import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
    <h1 style={{display:"flex", justifyContent:"center", alignItems:"center",}} > COMING SOON </h1>
    <p style={{display:"flex", justifyContent:"center", alignItems:"center",}} >manu@mhimanuhu.in</p>
    </>
  );
}

export default App;
